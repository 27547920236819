export const Base64 = (files) => {
    return new Promise((resolve,reject) => {
        const reader = new FileReader();
         reader.readAsDataURL(files)
         reader.onload = () => resolve(reader.result)
         reader.onerror = (error) => reject(error)
    })
 }

export const socialURL = [
    {
        url : "https://www.facebook.com/panther.codx",
        target : "_blank",
        icon : (<i className="mdi mdi-facebook"></i>)
    },
    {
        url : "https://twitter.com/PantherCodx",
        target : "_blank",
        icon : (<i className="mdi mdi-twitter"></i>)
    },
    {
        url : "https://www.linkedin.com/in/hr-panthercodx-llp-8b22a51b6/",
        target : "_blank",
        icon : (<i className="mdi mdi-linkedin"></i>)
    },
    {
        path : "*",
        url : "",
        target : "",
        icon : (<i className="mdi mdi-google-plus"></i>)
    },
    {
        path : "*",
        url : "",
        target : "",
        icon : (<i className="mdi mdi-dribbble"></i>)
    }
]