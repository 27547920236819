import { useEffect, useState } from "react"

const Loading = () => {
    return(
        <div className="page-load">
            <div className="container">
                <div className="brand-text">
                    <span>PantherCodx</span>
                    <span>PantherCodx</span>
                </div>
            </div>
        </div>
    )
}
export default Loading

export const LoadTimer = ({ children, timeOut = 1000 }) => {
    const [isShow, setIsShow] = useState(false)

    useEffect(() => {
        const time = setTimeout(() => {
            setIsShow(true);
        },timeOut)
        return () => clearTimeout(time)
    },[isShow])

    return isShow ? children : <Loading />
}