import HomePage from "../layout/pages/HomePage/HomePage";
import TermCondition from "../layout/pages/PrivacyPolicy/TermCondition";
import ApplyNow from "../homeSections/ApplyNow";
import CareersPage from "../layout/pages/CareersPage";
import PrivacyPolicy from "../layout/pages/PrivacyPolicy";

const routes = [
    {
        title: "Home",
        path:"/",
        Component: HomePage,
    },
    {
        title: "Home",
        path:"/home",
        Component: HomePage,
    },
    {
        title : "Career",
        path:"/careers",
        Component: CareersPage,
    },
    {
        title : "Career apply",
        path:"/careers-apply-schedule",
        Component: ApplyNow,
    },
    {
        title : "Terms & condition",
        path:"/term&condition",
        Component: TermCondition,
    },
    {
        title : "Privacy Policy",
        path:"/privacypolicy",
        Component: PrivacyPolicy,
    }
]

export default routes

// ---------------------------
// navigatin/header route
// ---------------------------

export const navRoutes = [
    {
      path : "/home",
      title : "Home"
    },
    {
      path : "/home#aboutus",
      title : "About Us"
    },
    {
      path : "/home#services",
      title : "Services"
    },
    {
      path : "/home#features",
      title : "Features"
    },
    {
      path : "/home#techweused",
      title : "Tech We Used"
    },
    {
      path : "/home#testimonials",
      title : "Testimonials"
    },
    {
      path : "/home#blog",
      title : "Blog"
    },
    {
      path : "/home#contact",
      title : "Contact"
    },
  ]